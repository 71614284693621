{{#items}}
  <li>
    <a>
      {{#avatar_thumb_url}}
        <img src="{{.}}" width="25" height="25" class="avatar" />
      {{/avatar_thumb_url}}
      
      {{label}}

      {{#jobTitle}}
      | {{.}}
      {{/jobTitle}}
      {{#team}}
        | {{{.}}}
      {{/team}}
    </a>
  </li>
{{/items}}
