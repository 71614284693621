<div class="recipient-wrapper" data-signature="{{signature}}" {{#if count}}data-count="{{count}}"{{/if}}>
  {{#avatar_thumb_url}}
    <div class='left marginRight10'>
      <img src="{{.}}" width='26px' class="avatar">
    </div>
  {{/avatar_thumb_url}}

  <div class='left marginRight25 paddingTop5'>
    <p>{{#if count includeZero=true}}({{count}}) {{/if}}{{name}}</p>
  </div>

  <div class='recipient-remove'>
    ✖
    <span class=offscreen>Remove</span>
  </div>
</div>
